// NavHeader.jsx
import React, { useMemo, useEffect, useRef, memo } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { matchPath, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import MeAvatar from '../container/MeAvatar.js';
import { LinkWithLanguage as Link } from '../component/LinkWithLanguage.jsx';
import Tooltip from '../component/Tooltip.jsx';
import HomeSearchBar from '../container/HomeSearchBar.js';
import UserLevelBadge from '../container/UserLevelBadge.js';
import NotificationButton from '../container/NotificationButton.js';
import BackpackButton from '../container/BackpackButton.js';
import LoginSignUpButton from '../container/LoginSignUpButton.js';
import ResetButtonStyle from '../style/ResetButtonStyle.js';
import media from '../style/media.js';

import {
  headerHeight,
  color,
  textColor,
  breakpoint,
} from '../style/variables.js';

import { ButtonId } from '../resource/mixpanel.js';
import { SHORT_PATHS } from '../resource/getShouldShowFooter.js';
import getIsMatchedPathname from '../resource/getIsMatchedPathname.js';
import { RANGE_24H } from '../resource/leaderboardConstants.js';
import getNavigationIcon, {
  NavigationId,
} from '../resource/getNavigationIcon.js';
import { TranslationNamespace } from '../resource/translationNamespace.js';
import defaults from '../resource/defaults.js';

import FreeBadgeIconSource from '../../img/img_badge_free.svg';
import CreatorIconSource from '../../img/ic_badge_creator.svg';
import BackIconSource from '../../img/ic_topbar_back.svg';
import ShopIconSource from '../../img/ic_diamond_shop.svg';
import MoreIconSource from '../../img/ic_topbar_more.svg';
import InfoIconSource from '../../img/ic_info_20.svg';
import MediaLibraryIconSource from '../../img/ic_media_library.svg';

import useNavHeader from '../hook/useNavHeader.js';

const AVATAR_SIZE = 32;
const AVATAR_BORDER_SIZE = 1;

const componentZIndex = {
  header: 8, // should higher than post action buttons
};
const navigationList = [
  {
    id: NavigationId.CHAT,
    pathArray: ['/chat/*'],
    isNeedAuth: false,
    buttonId: ButtonId.Tab.TabButtonChat,
    buttonNameI18nKey: 'tabbar_chat',
    getPath: () => '/chat',
    getIconSource: ({ isMatch, hasUnread } = {}) =>
      getNavigationIcon({
        navigationId: NavigationId.CHAT,
        isMatch,
        hasUnread,
      }),
  },
  {
    id: NavigationId.LEADERBOARD,
    pathArray: ['/leaderboard/:region/:category/:range'],
    isNeedAuth: false,
    buttonId: ButtonId.Tab.TabButtonLeaderboard,
    buttonNameI18nKey: 'leaderboard',
    getIconSource: ({ isMatch } = {}) =>
      getNavigationIcon({ navigationId: NavigationId.LEADERBOARD, isMatch }),
    getPath: ({ region, category } = {}) =>
      `/leaderboard/${region}/${category}/${RANGE_24H}`,
  },
];

export const NavHeader = ({
  clipCategory = null,
  meUsername = '',
  deviceWidth = 0,
  latestFeedRenewUnix = null,
  hasUnread = false,
  isAuthed = false,
  isDiamondShopOpen = false,
  isBackpackModalOpen = false,
  isUploadMenuModalOpen = false,
  isBecomeCreatorModalOpen = false,
  isNotificationCenterOpened = false,
  isHamburgerMenuOpen = false,
  isSearchModalOpened = false,
  isShortEnabled = false,
  feedNameWithQuery = null,
  renewFeedId = null,
  brandname = 'SWAG',
  shouldShowPasswordBindingHint = false,
  leaderboardRegions = defaults.EMPTY_ARRAY,
  leaderboardCategories = defaults.EMPTY_ARRAY,
  browseTabs = defaults.EMPTY_ARRAY,
  goBack = defaults.NULL_FUNCTION,
  openUploadMenuModal = defaults.NULL_FUNCTION,
  toggleModal = defaults.NULL_FUNCTION,
  addvalue = defaults.NULL_FUNCTION,
  fetchFeeds = defaults.NULL_FUNCTION,
  subscribeFeedChannel = defaults.NULL_FUNCTION,
  unsubscribeFeedChannel = defaults.NULL_FUNCTION,
}) => {
  const isOnMobile = deviceWidth <= breakpoint.mobile;
  const isOnDesktop = deviceWidth > breakpoint.tablet;
  const leaderboardComponentIndex = navigationList.findIndex(
    ({ id }) => NavigationId.LEADERBOARD === id
  );
  const { pathname, search = '' } = useLocation();
  const { t } = useTranslation();
  const clipCategoryRef = useRef(clipCategory);
  // Subscribe/Unsubscribe clip feed channel
  useEffect(() => {
    if (clipCategory) {
      clipCategoryRef.current = clipCategory;
      subscribeFeedChannel({ feedName: clipCategory });
    }
    return () => {
      unsubscribeFeedChannel({ feedName: clipCategoryRef.current });
    };
  }, [clipCategory, subscribeFeedChannel, unsubscribeFeedChannel]);

  const {
    titleSize,
    username,
    displayName,
    isDisplayNameEnabled,
    userId,
    title,
    titleTranslationNS,
    dataElementId,
    dataTrackingPayload,
    shouldFixedTop,
    shouldShowCreatorIcon,
    shouldShowUsernameOnMobile,
    isPrimaryTextOnly,
    shouldShowInfoOnMobile,
    shouldShowMoreOnMobile,
    shouldShowTitleOnMobile,
    shouldShowLogoOnMobile,
    shouldShowBackOnMobile,
    shouldShowLoginSignUpOnMobile,
    shouldShowDiamondShopOnMobile,
    shouldShowNotificationOnMobile,
    shouldShowSearchOnMobile,
    shouldShowSearchIfUnauthorizedOnMobile,
    shouldShowUploadOnMobile,
    shouldShowLeaderboardOnMobile,
    shouldShowChatroomMediaLibraryOnMobile,
    shouldAlwaysShowMobileLayout,
    shouldHidden,
    minWidth,
    onClickMore,
    onClickInfo,
    onClickSearch,
    onClickMediaLibrary,
  } = useNavHeader();

  const FeaturedList = useMemo(() => {
    const featuredList = [
      ...browseTabs
        .filter(tab => tab !== 'following' || isAuthed)
        .map(tab => {
          const result = {
            linkTo: `/${tab}`,
            pathArray: [`/${tab}`],
            isNeedAuth: false,
          };
          switch (tab) {
            case 'explore': {
              result.id = NavigationId.BROWSE_EXPLORE;
              result.linkTo = '/';
              result.pathArray = ['/'];
              result.buttonId = ButtonId.Tab.TabExplore;
              result.buttonNameI18nKey = 'explore_title';
              break;
            }
            case 'following': {
              result.id = NavigationId.BROWSE_FOLLOWING;
              result.buttonId = ButtonId.Tab.TabFollowing;
              result.buttonNameI18nKey = 'title_following_list';
              break;
            }
            case 'category-overview': {
              result.id = NavigationId.CATEGORY;
              result.buttonId = ButtonId.Tab.TabCategory;
              result.buttonNameI18nKey = 'general_categories';
            }
          }
          return result;
        }),
      {
        id: NavigationId.VIDEO,
        linkTo: '/video',
        pathArray: ['/video'],
        isNeedAuth: false,
        buttonId: ButtonId.Tab.TabButtonDiscover,
        buttonNameI18nKey: 'tabbar_discover',
      },
      {
        id: NavigationId.SHORTS,
        linkTo: '/shorts',
        pathArray: SHORT_PATHS,
        buttonId: ButtonId.Tab.TabButtonShorts,
        buttonNameI18nKey: 'shorts',
      },
    ];
    return (
      <FeaturedLinks>
        {featuredList.map(
          ({ id, pathArray, linkTo, buttonId, buttonNameI18nKey }) => {
            const isShort = NavigationId.SHORTS === id;
            if (isShort && !isShortEnabled) {
              return null;
            }
            const isMatch = pathArray.some(path => matchPath(path, pathname));
            const path = linkTo || `/${id}`;
            const isHome = '/' === linkTo;
            return (
              <FeaturedLink
                to={`${path}${search}`}
                key={path}
                title={t(buttonNameI18nKey, {
                  ns: TranslationNamespace.GENERAL,
                })}
                isMatch={isMatch}
                data-element_id={buttonId}
                onClick={() => {
                  if (isMatch) {
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                    if (isHome) {
                      fetchFeeds({
                        id: renewFeedId,
                        type: feedNameWithQuery,
                        page: 1,
                        unixTimestamp: latestFeedRenewUnix,
                      });
                    }
                  }
                }}
              >
                <FeaturedLinkContent isMatch={isMatch}>
                  {t(buttonNameI18nKey, {
                    ns: TranslationNamespace.GENERAL,
                  })}
                  {isShort && (
                    <FreeBadge
                      src={FreeBadgeIconSource}
                      width="30"
                      height="12"
                      alt="free badge"
                    />
                  )}
                </FeaturedLinkContent>
              </FeaturedLink>
            );
          }
        )}
      </FeaturedLinks>
    );
  }, [
    pathname,
    isShortEnabled,
    isAuthed,
    search,
    t,
    fetchFeeds,
    feedNameWithQuery,
    renewFeedId,
    latestFeedRenewUnix,
    browseTabs,
  ]);

  const NavigationComponentList = useMemo(() => {
    return navigationList.map(
      ({
        id,
        pathArray,
        isNeedAuth,
        buttonId,
        buttonNameI18nKey,
        getPath,
        getIconSource,
      }) => {
        if (isNeedAuth && !isAuthed) {
          return null;
        }
        let path = '';
        let iconSourceUrl = '';
        const isMatch = pathArray.some(path => matchPath(path, pathname));
        switch (id) {
          case NavigationId.CHAT: {
            path = getPath();
            iconSourceUrl = getIconSource({ isMatch, hasUnread });
            break;
          }
          case NavigationId.LEADERBOARD: {
            path = getPath({
              region: leaderboardRegions[0],
              category: leaderboardCategories[0],
            });
            iconSourceUrl = getIconSource({ isMatch, hasUnread });
            break;
          }
          default: {
            path = '';
            iconSourceUrl = '';
          }
        }
        return (
          <IconSection key={path}>
            <Tooltip
              position="bottom-center"
              renderHint={() => (
                <TooltipStyle>
                  {t(buttonNameI18nKey, {
                    ns: TranslationNamespace.GENERAL,
                  })}
                </TooltipStyle>
              )}
              trigger="hover"
              disabled={isOnMobile}
            >
              <Icon
                as={NavigationLink}
                to={path}
                isMatch={isMatch}
                aria-label={t(buttonNameI18nKey, {
                  ns: TranslationNamespace.GENERAL,
                })}
                data-element_id={buttonId}
              >
                <img
                  src={iconSourceUrl}
                  alt={t(buttonNameI18nKey, {
                    ns: TranslationNamespace.GENERAL,
                  })}
                />
              </Icon>
            </Tooltip>
          </IconSection>
        );
      }
    );
  }, [
    hasUnread,
    isAuthed,
    isOnMobile,
    leaderboardCategories,
    leaderboardRegions,
    pathname,
    t,
  ]);
  const Create = useMemo(() => {
    return (
      <IconSection>
        <Tooltip
          position="bottom-center"
          renderHint={() => (
            <TooltipStyle>
              {t('btn_create', {
                ns: TranslationNamespace.GENERAL,
              })}
            </TooltipStyle>
          )}
          trigger="hover"
          disabled={isOnMobile}
        >
          <CreateIcon
            isMatch={isUploadMenuModalOpen || isBecomeCreatorModalOpen}
            aria-label={t('btn_create', {
              ns: TranslationNamespace.GENERAL,
            })}
            data-element_id={ButtonId.Tab.TabButtonCreate}
            onClick={openUploadMenuModal}
          >
            <img
              src={getNavigationIcon({
                navigationId: NavigationId.CREATE,
                isMatch: isUploadMenuModalOpen || isBecomeCreatorModalOpen,
              })}
              alt={t('btn_create', {
                ns: TranslationNamespace.GENERAL,
              })}
            />
          </CreateIcon>
        </Tooltip>
      </IconSection>
    );
  }, [
    isUploadMenuModalOpen,
    isBecomeCreatorModalOpen,
    isOnMobile,
    t,
    openUploadMenuModal,
  ]);
  const Backpack = useMemo(() => {
    return (
      <IconSection>
        <Tooltip
          position="bottom-center"
          renderHint={() => (
            <TooltipStyle>
              {t('backpack_title', {
                ns: TranslationNamespace.GENERAL,
              })}
            </TooltipStyle>
          )}
          trigger="hover"
          disabled={isOnMobile}
        >
          <BackpackIcon
            data-element_id={ButtonId.Backpack.ButtonBackpack}
            as="div"
            isMatch={isBackpackModalOpen}
            aria-label={t('backpack_title', {
              ns: TranslationNamespace.GENERAL,
            })}
            onClick={() => {
              return toggleModal({
                modalId: 'BackpackModal',
                payload: {},
                isOpen: true,
                modalOption: {},
              });
            }}
          >
            <BackpackButton iconSize={28} shouldUseActiveIcon />
          </BackpackIcon>
        </Tooltip>
      </IconSection>
    );
  }, [isBackpackModalOpen, isOnMobile, t, toggleModal]);
  const DiamondShop = useMemo(() => {
    return (
      <IconSection>
        <Tooltip
          position="bottom-center"
          renderHint={() => (
            <TooltipStyle>
              {t('buy_points', {
                ns: TranslationNamespace.GENERAL,
              })}
            </TooltipStyle>
          )}
          trigger="hover"
          disabled={isOnMobile}
        >
          <DiamondShopIcon
            data-element_id={ButtonId.All.ButtonDiamondShop}
            isMatch={isDiamondShopOpen}
            aria-label={t('buy_points', {
              ns: TranslationNamespace.GENERAL,
            })}
            onClick={addvalue}
          >
            <img
              src={ShopIconSource}
              alt={t('buy_points', {
                ns: TranslationNamespace.GENERAL,
              })}
            />
          </DiamondShopIcon>
        </Tooltip>
      </IconSection>
    );
  }, [isDiamondShopOpen, isOnMobile, t, addvalue]);
  const More = useMemo(() => {
    return (
      <MoreIcon
        data-element_id={dataElementId}
        onClick={onClickMore}
        title={t('setting_button_more', {
          ns: TranslationNamespace.GENERAL,
        })}
      >
        <img
          src={MoreIconSource}
          alt={t('setting_button_more', {
            ns: TranslationNamespace.GENERAL,
          })}
        />
      </MoreIcon>
    );
  }, [t, onClickMore, dataElementId]);
  const ChatroomMediaLibrary = useMemo(() => {
    return (
      <MoreIcon
        data-element_id={dataElementId}
        onClick={onClickMediaLibrary}
        title={t('media_library', {
          ns: TranslationNamespace.CHAT,
        })}
      >
        <img
          src={MediaLibraryIconSource}
          alt={t('media_library', {
            ns: TranslationNamespace.CHAT,
          })}
        />
      </MoreIcon>
    );
  }, [t, onClickMediaLibrary, dataElementId]);
  const Info = useMemo(() => {
    return (
      <InfoIcon
        data-element_id={dataElementId}
        data-tracking_payload={dataTrackingPayload}
        onClick={onClickInfo}
        title={t('swagr_about_more_information', {
          ns: TranslationNamespace.GENERAL,
        })}
      >
        <img
          src={InfoIconSource}
          alt={t('swagr_about_more_information', {
            ns: TranslationNamespace.GENERAL,
          })}
        />
      </InfoIcon>
    );
  }, [t, onClickInfo, dataElementId, dataTrackingPayload]);
  const Hamburger = useMemo(() => {
    return (
      <IconSection>
        <Tooltip
          position="bottom-center"
          renderHint={() => (
            <TooltipStyle>
              {t('tabbar_menu', {
                ns: TranslationNamespace.GENERAL,
              })}
            </TooltipStyle>
          )}
          trigger="hover"
          disabled={isOnMobile}
        >
          <HamburgerIcon
            data-element_id={ButtonId.Tab.TabButtonHamburger}
            isMatch={isHamburgerMenuOpen}
            aria-label={t('tabbar_menu', {
              ns: TranslationNamespace.GENERAL,
            })}
            onClick={() => {
              return toggleModal({
                modalId: 'HamburgerMenuModal',
                payload: {},
                isOpen: !isHamburgerMenuOpen,
                modalOption: {
                  isHigherThanAll: true,
                },
              });
            }}
          >
            <img
              src={getNavigationIcon({
                navigationId: NavigationId.ME,
                isMatch: isHamburgerMenuOpen,
                isAuthed: false,
              })}
              alt={t('tabbar_menu', {
                ns: TranslationNamespace.GENERAL,
              })}
            />
          </HamburgerIcon>
        </Tooltip>
      </IconSection>
    );
  }, [isHamburgerMenuOpen, isOnMobile, t, toggleModal]);
  const MeAvatarButton = useMemo(() => {
    const isMatch =
      isHamburgerMenuOpen ||
      getIsMatchedPathname({
        pathname,
        dataKey: 'isOnMeProfile',
      });
    return (
      <MeAvatarSection>
        <Tooltip
          position="bottom-center"
          renderHint={() => (
            <TooltipStyle>
              {t('tabbar_menu', {
                ns: TranslationNamespace.GENERAL,
              })}
            </TooltipStyle>
          )}
          trigger="hover"
          disabled={isOnMobile}
        >
          <UserButton
            data-element_id={ButtonId.Tab.TabButtonHamburger}
            aria-label={t('tabbar_menu', {
              ns: TranslationNamespace.GENERAL,
            })}
            onClick={() => {
              return toggleModal({
                modalId: 'HamburgerMenuModal',
                payload: {},
                isOpen: !isHamburgerMenuOpen,
                modalOption: {
                  isHigherThanAll: true,
                },
              });
            }}
          >
            <Avatar isMatch={isMatch}>
              <>
                <MeAvatar
                  size={AVATAR_SIZE - AVATAR_BORDER_SIZE * 2}
                  alt={meUsername}
                />
                {shouldShowPasswordBindingHint && <RedDot />}
                <MenuIconWrapper isMatch={isMatch}>
                  <img
                    src={getNavigationIcon({
                      navigationId: NavigationId.ME,
                      isMatch,
                      isAuthed: true,
                    })}
                    alt="menu icon"
                  />
                </MenuIconWrapper>
              </>
            </Avatar>
          </UserButton>
        </Tooltip>
      </MeAvatarSection>
    );
  }, [
    meUsername,
    pathname,
    isHamburgerMenuOpen,
    isOnMobile,
    shouldShowPasswordBindingHint,
    t,
    toggleModal,
  ]);
  const Logo = useMemo(() => {
    return (
      <LogoLink to="/" title="logo">
        <img src="/logo/header" alt={brandname} />
      </LogoLink>
    );
  }, [brandname]);
  const Back = useMemo(() => {
    return (
      <BackIcon
        aria-label={t('back', {
          ns: TranslationNamespace.GENERAL,
        })}
        data-element_id={ButtonId.All.ButtonBack}
        onClick={goBack}
      >
        <img
          src={BackIconSource}
          alt={t('back', {
            ns: TranslationNamespace.GENERAL,
          })}
        />
      </BackIcon>
    );
  }, [t, goBack]);
  const Title = useMemo(() => {
    return (
      <HeaderTitle
        titleSize={titleSize}
        onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
      >
        {t(title, {
          ns: titleTranslationNS,
        })}
      </HeaderTitle>
    );
  }, [t, title, titleSize, titleTranslationNS]);
  const UsernameWithIndicator = useMemo(() => {
    return (
      <>
        <UsernameWithStatus>
          <NameWrapper>
            <PrimaryTextWrapper>
              <PrimaryText as={Link} to={`/user/${userId}`}>
                {isDisplayNameEnabled && displayName
                  ? displayName
                  : username
                    ? `@${username}`
                    : '-'}
              </PrimaryText>
              <UserLevelBadgeWrapper>
                <UserLevelBadge
                  shouldDirectToInformation={true}
                  userId={userId}
                />
              </UserLevelBadgeWrapper>
              {shouldShowCreatorIcon && <CreatorIcon src={CreatorIconSource} />}
            </PrimaryTextWrapper>
            {!isPrimaryTextOnly && (
              <SecondaryText>
                {isDisplayNameEnabled &&
                  displayName &&
                  (username ? `@${username}` : '-')}
              </SecondaryText>
            )}
          </NameWrapper>
        </UsernameWithStatus>
      </>
    );
  }, [
    userId,
    isDisplayNameEnabled,
    displayName,
    username,
    shouldShowCreatorIcon,
    isPrimaryTextOnly,
  ]);
  const SearchBar = useMemo(() => {
    const isOnSearch = pathname.startsWith('/search');
    const shouldDisplaySearchBar = isOnDesktop && !isOnSearch;
    return (
      shouldDisplaySearchBar && (
        <SearchBarWrapper>
          <HomeSearchBar />
        </SearchBarWrapper>
      )
    );
  }, [isOnDesktop, pathname]);
  const Notification = useMemo(() => {
    return (
      <IconSection>
        <Tooltip
          position="bottom-center"
          renderHint={() => (
            <TooltipStyle>
              {t('notification_center_title', {
                ns: TranslationNamespace.GENERAL,
              })}
            </TooltipStyle>
          )}
          trigger="hover"
          disabled={isOnMobile}
        >
          <NotificationIcon
            as="div"
            isMatch={isNotificationCenterOpened}
            aria-label={t('notification_center_title', {
              ns: TranslationNamespace.GENERAL,
            })}
          >
            <NotificationButton />
          </NotificationIcon>
        </Tooltip>
      </IconSection>
    );
  }, [t, isNotificationCenterOpened, isOnMobile]);
  const Search = useMemo(() => {
    return (
      <IconSection>
        <Tooltip
          position="bottom-center"
          renderHint={() => (
            <TooltipStyle>
              {t('tabbar_search', {
                ns: TranslationNamespace.GENERAL,
              })}
            </TooltipStyle>
          )}
          trigger="hover"
          disabled={isOnMobile}
        >
          <SearchIcon
            data-element_id={dataElementId}
            onClick={onClickSearch}
            isMatch={isSearchModalOpened}
            aria-label={t('tabbar_search', {
              ns: TranslationNamespace.GENERAL,
            })}
          >
            <img
              src={getNavigationIcon({
                navigationId: NavigationId.SEARCH,
                isMatch: isSearchModalOpened,
              })}
              alt={t('tabbar_search', {
                ns: TranslationNamespace.GENERAL,
              })}
            />
          </SearchIcon>
        </Tooltip>
      </IconSection>
    );
  }, [t, isSearchModalOpened, isOnMobile, onClickSearch, dataElementId]);

  if (minWidth >= deviceWidth) return null;
  if (shouldHidden) return null;

  if (isOnMobile || shouldAlwaysShowMobileLayout) {
    return (
      <StyledNavHeader shouldFixedTop={shouldFixedTop}>
        <LeftSide>
          {shouldShowLogoOnMobile && Logo}
          {shouldShowBackOnMobile && Back}
          {shouldShowTitleOnMobile && Title}
          {shouldShowUsernameOnMobile && UsernameWithIndicator}
        </LeftSide>
        <RightSide>
          {!isAuthed ? (
            <>
              {shouldShowSearchIfUnauthorizedOnMobile && Search}
              {shouldShowLoginSignUpOnMobile && <LoginSignUpButton />}
            </>
          ) : (
            <>
              {shouldShowSearchOnMobile && Search}
              {shouldShowLeaderboardOnMobile &&
                leaderboardComponentIndex >= 0 &&
                NavigationComponentList[leaderboardComponentIndex]}
              {shouldShowUploadOnMobile && Create}
              {shouldShowNotificationOnMobile && Notification}
              {shouldShowDiamondShopOnMobile && DiamondShop}
              {shouldShowMoreOnMobile && More}
              {shouldShowChatroomMediaLibraryOnMobile && ChatroomMediaLibrary}
              {shouldShowInfoOnMobile && Info}
            </>
          )}
        </RightSide>
      </StyledNavHeader>
    );
  }

  return (
    <StyledNavHeader shouldFixedTop={shouldFixedTop}>
      <LeftSide>
        {Logo}
        {FeaturedList}
      </LeftSide>
      <RightSide>
        {SearchBar}
        {NavigationComponentList}
        {isAuthed && Create}
        {isAuthed && Notification}
        {Backpack}
        {DiamondShop}
        {isAuthed ? MeAvatarButton : <LoginSignUpButton />}
        {!isAuthed && Hamburger}
      </RightSide>
    </StyledNavHeader>
  );
};

NavHeader.propTypes = {
  clipCategory: PropTypes.string,
  deviceWidth: PropTypes.number,
  latestFeedRenewUnix: PropTypes.number,
  meUsername: PropTypes.string,
  feedNameWithQuery: PropTypes.string,
  renewFeedId: PropTypes.string,
  brandname: PropTypes.string,
  isAuthed: PropTypes.bool,
  hasUnread: PropTypes.bool,
  shouldShowPasswordBindingHint: PropTypes.bool,
  isHamburgerMenuOpen: PropTypes.bool,
  isDiamondShopOpen: PropTypes.bool,
  isBackpackModalOpen: PropTypes.bool,
  isUploadMenuModalOpen: PropTypes.bool,
  isBecomeCreatorModalOpen: PropTypes.bool,
  isNotificationCenterOpened: PropTypes.bool,
  isSearchModalOpened: PropTypes.bool,
  isShortEnabled: PropTypes.bool,
  leaderboardRegions: PropTypes.array,
  leaderboardCategories: PropTypes.array,
  browseTabs: PropTypes.array,
  goBack: PropTypes.func,
  openUploadMenuModal: PropTypes.func,
  toggleModal: PropTypes.func,
  addvalue: PropTypes.func,
  fetchFeeds: PropTypes.func,
  subscribeFeedChannel: PropTypes.func,
  unsubscribeFeedChannel: PropTypes.func,
};

const StyledNavHeader = styled.header`
  ${({ shouldFixedTop }) => {
    return css`
      position: ${shouldFixedTop ? 'fixed' : 'static'};
      top: ${shouldFixedTop ? 'var(--space-to-pinned-notification)' : 'auto'};
      left: ${shouldFixedTop ? '0px' : 'auto'};
    `;
  }}
  z-index: ${componentZIndex.header};
  border-bottom: 1px solid rgba(100, 100, 100, 0.15);
  padding-left: 20px;
  padding-right: 20px;
  width: 100%;
  height: ${headerHeight}px;
  display: flex;
  align-items: center;
  background-color: ${color.black};
  ${media.mobile`
    border-bottom: none;
    padding-left: 16px;
    padding-right: 16px;
  `};
`;

const HeaderTitle = styled.div`
  line-height: 1;
  font-weight: 600;
  color: ${color.white};
  font-size: ${({ titleSize }) => titleSize}px;
  cursor: pointer;
`;

const Side = styled.nav`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LeftSide = styled(Side)`
  margin-right: 12px;
  flex: auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
`;

const RightSide = styled(Side)`
  flex: none;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const LogoLink = styled(Link)`
  margin-right: 20px;
  flex: none;
  display: flex;
  align-items: center;
  > img {
    width: auto;
    height: 36px;
  }
  ${media.tablet`
    margin-right: 20px;
  `};
  ${media.mobile`
    margin-right: 0px;
  `};
`;

const Icon = styled.button`
  ${ResetButtonStyle};
  position: relative;
  flex: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  transition: 0.3s;
  background-color: ${({ isMatch }) =>
    isMatch ? 'rgba(0, 126, 114, 0.2)' : 'transparent'};
  opacity: 1;
  > img {
    width: 28px;
    height: 28px;
    object-fit: contain;
  }
  @media (hover: hover) {
    :hover {
      background-color: rgba(155, 155, 155, 0.2);
      opacity: 1;
    }
  }
`;

const BackIcon = styled(Icon)`
  margin-right: 8px;
  flex: none;
`;
const CreateIcon = styled(Icon)``;
const NotificationIcon = styled(Icon)``;
const HamburgerIcon = styled(Icon)``;
const MoreIcon = styled(Icon)``;
const InfoIcon = styled(Icon)``;
const DiamondShopIcon = styled(Icon)``;
const BackpackIcon = styled(Icon)``;
const SearchIcon = styled(Icon)``;

const NavigationLink = styled(({ isMatch, ...restProps }) => (
  <Link {...restProps} />
))``;

const IconSection = styled.div`
  margin-left: 8px;
  ${media.mobile`
    margin-left: 4px;
  `};
`;

const MeAvatarSection = styled.div`
  margin-left: 10px;
`;

const FeaturedLinks = styled.div`
  padding-right: 14px;
  height: 100%;
  display: flex;
  align-items: center;

  white-space: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  ::-webkit-scrollbar {
    display: none;
  }
`;

const FeaturedLink = styled(({ isMatch, ...restProps }) => (
  <Link {...restProps} />
))`
  position: relative;
  margin-left: 4px;
  height: 100%;
  display: inline-flex;
  align-items: center;
  :first-child {
    margin-left: 0px;
  }
  ${media.mobile`
    display: none;
  `};
  @media (hover: hover) {
    :hover {
      opacity: 1;
    }
  }
  ::after {
    content: '';
    position: absolute;
    bottom: 0px;
    left: 8px;
    right: 8px;
    border-bottom: ${({ isMatch }) =>
      isMatch ? `solid 4px ${color.tealBlue}` : 'none'};
  }
`;

const FeaturedLinkContent = styled.div`
  position: relative;
  border-radius: 20px;
  padding: 10px 12px;
  line-height: 24px;
  white-space: nowrap;
  font-size: 22px;
  color: ${textColor.white};
  ${media.tablet`
    padding: 8px;
    font-size: 16px;
  `}
  @media (hover: hover) {
    :hover {
      background-color: ${({ isMatch }) =>
        isMatch ? 'transparent' : 'rgba(155, 155, 155, 0.2)'};
    }
  }
`;

const FreeBadge = styled.img`
  position: absolute;
  top: 0px;
  right: -6px;
  height: 12px;
  transform: rotate(10deg);
`;

const UserButton = styled.button`
  ${ResetButtonStyle};
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  max-width: 160px;
  padding: 4px;
  border-radius: 20px;
  transition: 0.3s;
  cursor: pointer;
  ${media.tablet`
    border-radius: 50%;
  `};
`;

const Avatar = styled.div`
  position: relative;
  border-radius: 50%;
  border-style: solid;
  border-width: ${AVATAR_BORDER_SIZE}px;
  border-color: ${({ isMatch }) => (isMatch ? color.tealBlue : 'transparent')};
  width: ${AVATAR_SIZE}px;
  height: ${AVATAR_SIZE}px;
  cursor: pointer;
`;

const MenuIconWrapper = styled.div.attrs(({ isMatch }) => ({
  style: {
    backgroundColor: isMatch ? color.tealBlue : color.white,
  },
}))`
  position: absolute;
  bottom: 0px;
  right: 0px;
  border-radius: 50%;
  border: 1px solid ${color.black};
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  > img {
    width: 12px;
    height: 12px;
    object-fit: contain;
  }
`;

const RedDot = styled.div`
  position: absolute;
  top: 0px;
  right: -4px;
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background-color: #cf0404;
  border: solid 2px #000000;
`;

const UsernameWithStatus = styled.div`
  font-weight: 600;
  color: ${color.white};
  display: flex;
  overflow: hidden;
  ${media.mobile`
    align-items: baseline;
  `}
`;

const NameWrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const PrimaryTextWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const PrimaryText = styled.div`
  color: var(--font-color-primary-on-dark, #fff);
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  letter-spacing: 0.03px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const SecondaryText = styled.div`
  color: var(--font-color-secondary-on-dark, rgba(221, 221, 221, 0.8));
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.012px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const UserLevelBadgeWrapper = styled.div`
  margin-left: 4px;
`;

const CreatorIcon = styled.img`
  margin-left: 4px;
  width: 16px;
  height: 16px;
  object-fit: contain;
`;

const TooltipStyle = styled.div`
  min-width: 44px;
  height: 30px;
  padding: 7px;
  border-radius: 8px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.5);
  background-color: rgba(246, 246, 246, 0.8);
  color: ${color.black};
  font-size: 14px;
  text-align: center;
  white-space: nowrap;
  margin-top: 8px;
`;

const SearchBarWrapper = styled.div`
  width: 260px;
`;

export default memo(NavHeader);

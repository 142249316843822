// useNavHeader.js
import { useMemo } from 'react';
import { matchPath, useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';

import {
  HELP_ARTICLE_ID_MESSAGE_PACK,
  BROWSE_TABS,
  FEATURE_DISPLAY_NAME,
  JWT_SCOPE_CREATOR,
} from '../RemoteConfigKeys.js';

import useMessageDetailType from '../hook/useMessageDetailType.js';

import { UserMoreFeature } from '../resource/profileConstants.js';
import { LIVESTREAM_PATH } from '../resource/liveStreamConstants.js';
import { ButtonId } from '../resource/mixpanel.js';
import { SEARCH_MODAL_ID } from '../resource/searchConstants.js';
import { breakpoint } from '../style/variables.js';
import { SHORT_PATHS } from '../resource/getShouldShowFooter.js';

import { push } from '../action/navigationAction.js';
import toggleModal from '../action/toggleModal.js';

import getMessageData from '../selector/getMessageData.js';
import getUserData from '../selector/getUserData.js';
import getMeData from '../selector/getMeData.js';
import { MessageMoreFeature } from '../resource/messageConstants.js';
import getShareLink from '../resource/getShareLink.js';
import getCanShare from '../resource/getCanShare.js';
import { ProductType } from '../resource/shopConstants.js';
import getRemoteConfigData from '../selector/getRemoteConfigData.js';
import getProductData from '../selector/getProductData.js';
import getUserBadges from '../selector/getUserBadges.js';
import getIsPermissionMatched from '../selector/getIsPermissionMatched.js';
import getUserDataByUsername from '../selector/getUserDataByUsername.js';
import { TranslationNamespace } from '../resource/translationNamespace.js';
import getIsMatchedPathname from '../resource/getIsMatchedPathname.js';

const canShare = getCanShare();

export const defaultData = {
  titleSize: 22,
  minWidth: 0,
  username: '',
  displayName: '',
  isDisplayNameEnabled: true,
  userId: null,
  title: '',
  titleTranslationNS: TranslationNamespace.GENERAL,
  shouldFixedTop: false,
  shouldShowCreatorIcon: false,
  shouldShowUsernameOnMobile: false,
  shouldShowMoreOnMobile: false,
  shouldShowTitleOnMobile: false,
  shouldShowLogoOnMobile: false,
  shouldShowBackOnMobile: false,
  shouldShowLoginSignUpOnMobile: false,
  shouldShowDiamondShopOnMobile: false,
  shouldShowNotificationOnMobile: false,
  shouldShowUploadOnMobile: false,
  shouldShowSearchOnMobile: false,
  shouldShowSearchIfUnauthorizedOnMobile: false,
  shouldShowLeaderboardOnMobile: false,
  shouldHidden: false,
  shouldShowChatroomMediaLibraryOnMobile: false,
  shouldAlwaysShowMobileLayout: false,
  onClickMore: () => null,
  onClickSearch: () => null,
  onClickMediaLibrary: () => null,
};

const browseTabTitle = {
  explore: 'explore_title',
  following: 'title_following_list',
  'category-overview': 'general_categories',
};

export function useNavHeader() {
  const {
    userId: userIdFromParam = '',
    username: usernameFromParam = '',
    chatroomId: chatroomIdFromParam = '',
    messageId = '',
    productId = '',
  } = useParams();
  const dispatch = useDispatch();
  const senderId = useSelector(state =>
    getMessageData(state, messageId, 'senderId')
  );
  const isPublished = useSelector(state =>
    getMessageData(state, messageId, 'isPublished')
  );
  const userId = useSelector(
    state =>
      userIdFromParam ||
      getUserDataByUsername(state, usernameFromParam, 'id') ||
      senderId
  );
  const meId = useSelector(state => getMeData(state, 'id'));
  const isMeProfile = meId && meId === userId;

  const username = useSelector(
    state =>
      usernameFromParam ||
      (isMeProfile
        ? getMeData(state, 'username')
        : getUserData(state, userId, 'username'))
  );
  const isCP = useSelector(state =>
    getIsPermissionMatched(state, JWT_SCOPE_CREATOR)
  );
  const hasSwaggerBadge = useSelector(state =>
    getUserBadges(state, userId, 'hasSwaggerBadge')
  );
  const shouldShowCreatorIcon = isMeProfile ? isCP : hasSwaggerBadge;
  const displayName = useSelector(state =>
    isMeProfile
      ? getMeData(state, 'displayName')
      : getUserData(state, userId, 'displayName')
  );
  const isDisplayNameEnabled = useSelector(
    state => getRemoteConfigData(state, FEATURE_DISPLAY_NAME) === 1
  );
  const productType = useSelector(state =>
    getProductData(state, [productId], 'type')
  );
  const articleConfigKey = productId
    ? ProductType.MESSAGEPACKS === productType
      ? HELP_ARTICLE_ID_MESSAGE_PACK
      : undefined
    : undefined;
  const articleId = useSelector(state =>
    getRemoteConfigData(state, articleConfigKey)
  );
  const browseTabs = useSelector(state =>
    getRemoteConfigData(state, BROWSE_TABS)
  );

  const { pathname, state: locationState, search } = useLocation();
  const layoutType = useMessageDetailType();

  let iframeViewerTitle = 'iframe_viewer_header';
  if (pathname.startsWith('/iframe')) {
    try {
      const searchParams = new URLSearchParams(search);
      const title = searchParams.get('title');
      if (title) iframeViewerTitle = title;
    } catch {
      // No need to explicitly handle the error, iframeViewerTitle remains as 'iframe_viewer_header'
    }
  }

  return useMemo(() => {
    const userProfileDefaultData = {
      shouldFixedTop: true,
      shouldShowUsernameOnMobile: true,
      shouldShowLoginSignUpOnMobile: true,
      shouldShowMoreOnMobile: true,
      shouldShowLeaderboardOnMobile: false,
      shouldShowCreatorIcon,
      isDisplayNameEnabled,
      displayName,
      username,
      userId: isMeProfile ? meId : userId,
      shouldShowBackOnMobile: true,
      shouldShowNotificationOnMobile: true,
      shouldShowDiamondShopOnMobile: true,
      dataElementId: ButtonId.All.ButtonMore,
      onClickMore: () => {
        return dispatch(
          toggleModal({
            modalId: 'ProfileMoreModal',
            payload: {
              userId: isMeProfile ? meId : userId,
              features: [
                ...(!isMeProfile ? [UserMoreFeature.BLOCK] : []),
                canShare ? UserMoreFeature.SHARE : UserMoreFeature.COPY,
              ],
            },
            isOpen: true,
          })
        );
      },
    };
    const browseDefaultData = {
      patterns: [],
      shouldFixedTop: true,
      shouldShowLoginSignUpOnMobile: true,
      shouldShowUploadOnMobile: true,
      shouldShowLeaderboardOnMobile: true,
      shouldShowNotificationOnMobile: true,
      shouldShowDiamondShopOnMobile: true,
      shouldShowSearchOnMobile: true,
      shouldShowSearchIfUnauthorizedOnMobile: true,
      shouldShowLogoOnMobile: true,
      dataElementId: ButtonId.Tab.TabButtonSearch,
      onClickSearch: () => {
        return dispatch(
          toggleModal({
            modalId: SEARCH_MODAL_ID,
            payload: {},
            isOpen: true,
          })
        );
      },
    };

    const pathDataList = [
      ...browseTabs.map(tab => {
        const id = `browse-${tab}`;
        const patterns = [`/${tab}`];
        const title = browseTabTitle[tab];
        if ('category-overview' === tab) {
          return {
            id,
            patterns,
            title,
            titleSize: 16,
            shouldFixedTop: true,
            shouldShowBackOnMobile: true,
            shouldShowTitleOnMobile: true,
          };
        }
        return {
          ...browseDefaultData,
          id,
          patterns,
          title,
        };
      }),
      {
        id: 'feed',
        patterns: ['/feed'],
        shouldFixedTop: true,
        title: 'feeds_title',
        shouldShowLoginSignUpOnMobile: true,
        shouldShowUploadOnMobile: true,
        shouldShowLeaderboardOnMobile: true,
        shouldShowNotificationOnMobile: true,
        shouldShowDiamondShopOnMobile: true,
        shouldShowTitleOnMobile: true,
      },
      {
        id: 'home',
        patterns: ['/'],
        shouldFixedTop: true,
        title: '',
        shouldShowLoginSignUpOnMobile: true,
        shouldShowUploadOnMobile: true,
        shouldShowLeaderboardOnMobile: true,
        shouldShowNotificationOnMobile: true,
        shouldShowDiamondShopOnMobile: true,
        shouldShowLogoOnMobile: true,
        shouldShowSearchOnMobile: true,
        shouldShowSearchIfUnauthorizedOnMobile: true,
        shouldShowTitleOnMobile: false,
        dataElementId: ButtonId.Tab.TabButtonSearch,
        onClickSearch: () => {
          return dispatch(
            toggleModal({
              modalId: SEARCH_MODAL_ID,
              payload: {},
              isOpen: true,
            })
          );
        },
      },
      {
        id: 'home-video',
        patterns: ['/video'],
        shouldFixedTop: true,
        title: 'tabbar_discover',
        shouldShowLoginSignUpOnMobile: true,
        shouldShowUploadOnMobile: true,
        shouldShowLeaderboardOnMobile: true,
        shouldShowNotificationOnMobile: true,
        shouldShowDiamondShopOnMobile: true,
        shouldShowLogoOnMobile: false,
        shouldShowTitleOnMobile: true,
      },
      {
        id: 'livestreams',
        patterns: [
          `/user/:userId/${LIVESTREAM_PATH}`,
          `/u/:username/${LIVESTREAM_PATH}`,
        ],
        shouldFixedTop: true,
        title: 'home_feature',
        minWidth: breakpoint.tablet,
      },
      {
        id: 'user-profile',
        patterns: [
          '/user/:userId',
          '/u/:username',
          '/user/:userId/:listType',
          '/u/:username/:listType',
          '/user/:userId/:listType/:publishStatus',
          '/u/:username/:listType/:publishStatus',
        ],
        ...userProfileDefaultData,
      },
      {
        id: 'chat-message-detail',
        patterns: ['/chat/:chatroomId/message/:messageId'],
        shouldFixedTop: true,
        shouldShowUsernameOnMobile: true,
        shouldShowLoginSignUpOnMobile: true,
        isDisplayNameEnabled,
        displayName,
        username,
        userId: senderId,
        shouldShowBackOnMobile: true,
        shouldShowChatroomMediaLibraryOnMobile:
          locationState?.shouldShowChatroomMediaLibrary,
        dataElementId: ButtonId.Chatroom.ButtonMediaLibrary,
        onClickMediaLibrary: () => {
          return dispatch(push(`/chat/${chatroomIdFromParam}/media`));
        },
      },
      {
        id: 'chat',
        patterns: ['/chat/*'],
        shouldFixedTop: true,
        minWidth: breakpoint.mobile,
      },
      {
        id: 'flix-detail',
        patterns: ['/post/:postId'],
        title: !isPublished ? 'scheduled_post' : '',
        titleSize: 18,
        shouldFixedTop: true,
        isDisplayNameEnabled,
        displayName,
        username,
        userId,
        shouldShowBackOnMobile: true,
        shouldShowTitleOnMobile: !isPublished,
        shouldShowUsernameOnMobile: isPublished,
        isPrimaryTextOnly: true,
        shouldShowLoginSignUpOnMobile: true,
        shouldShowMoreOnMobile: isPublished,
        dataElementId: ButtonId.All.ButtonMore,
        onClickMore: () => {
          return dispatch(
            toggleModal({
              modalId: 'MessageMoreModal',
              payload: {
                messageId,
                features: [
                  MessageMoreFeature.BLOCK,
                  MessageMoreFeature.FOLLOW,
                  MessageMoreFeature.PROFILE,
                  MessageMoreFeature.CLIP,
                  MessageMoreFeature.PIN,
                  MessageMoreFeature.EDIT,
                  MessageMoreFeature.HIDE,
                ],
                shareLink: getShareLink({
                  pathname: `/post/${messageId}`,
                }),
              },
              isOpen: true,
            })
          );
        },
      },
      {
        id: 'post-detail',
        patterns: ['/post/:postId'],
        shouldFixedTop: true,
        title: !isPublished ? 'scheduled_post' : 'post_title',
        titleSize: 18,
        shouldShowLoginSignUpOnMobile: true,
        shouldShowBackOnMobile: true,
        shouldShowTitleOnMobile: true,
      },
      {
        id: 'short-detail',
        patterns: ['/post/:postId'],
        shouldFixedTop: true,
        minWidth: breakpoint.mobile,
      },
      {
        id: 'story-detail',
        patterns: ['/story/:messageId'],
        shouldFixedTop: true,
        minWidth: breakpoint.mobile,
      },
      {
        id: 'message-detail',
        patterns: ['/message/:messageId'],
        shouldFixedTop: true,
        minWidth: breakpoint.mobile,
      },
      {
        id: 'category-detail',
        patterns: ['/category/:categoryId', '/category/:categoryId/:listType'],
        shouldFixedTop: true,
        minWidth: breakpoint.mobile,
      },
      {
        id: 'search',
        patterns: ['/search/*'],
        shouldFixedTop: true,
        minWidth: breakpoint.mobile,
      },
      {
        id: 'shorts',
        patterns: SHORT_PATHS,
        shouldFixedTop: true,
        minWidth: breakpoint.mobile,
      },
      {
        id: 'product-detail',
        patterns: ['/product/:productId'],
        shouldFixedTop: true,
        shouldShowBackOnMobile: true,
        shouldShowTitleOnMobile: true,
        shouldShowInfoOnMobile: true,
        title: 'message_packs',
        titleSize: 18,
        dataElementId: ButtonId.All.ButtonInformation,
        dataTrackingPayload: {
          'article.config_key': articleConfigKey,
        },
        onClickInfo: () => {
          return dispatch(
            toggleModal({
              modalId: 'IntercomArticleModal',
              payload: {
                articleId,
                articleConfigKey,
              },
              isOpen: true,
              modalOption: {
                transitionStatus: 'coming',
              },
            })
          );
        },
      },
      {
        id: 'user-feed-grid',
        patterns: ['/user-feed-grid/:category'],
        shouldFixedTop: true,
        minWidth: breakpoint.mobile,
      },
      {
        id: 'user-leaderboard-grid',
        patterns: ['/user-leaderboard-grid/:category'],
        shouldFixedTop: true,
        minWidth: breakpoint.mobile,
      },
      {
        id: 'user-following-grid',
        patterns: ['/user-following-grid/:category'],
        shouldFixedTop: true,
        minWidth: breakpoint.mobile,
      },
      {
        id: 'user-event',
        patterns: ['/user-event'],
        shouldFixedTop: true,
        title: 'user_campaign',
        titleSize: 16,
        shouldShowLoginSignUpOnMobile: true,
        shouldShowBackOnMobile: true,
        shouldShowTitleOnMobile: true,
      },
      {
        id: 'iframe-viewer',
        patterns: ['/iframe'],
        shouldFixedTop: true,
        titleSize: 16,
        title: iframeViewerTitle,
        titleTranslationNS: TranslationNamespace.CAMPAIGN,
        shouldShowTitleOnMobile: true,
        shouldShowBackOnMobile: true,
      },
      {
        id: 'vvip',
        patterns: ['/settings/vvip'],
        shouldFixedTop: true,
        title: 'vip_level_title',
        titleSize: 22,
        shouldShowDiamondShopOnMobile: true,
        shouldShowBackOnMobile: true,
        shouldShowTitleOnMobile: true,
        shouldShowNotificationOnMobile: true,
        shouldAlwaysShowMobileLayout: true,
      },
    ];

    const matchedData =
      pathDataList.find(({ patterns, id }) => {
        const foundPattern = patterns.find(pattern =>
          matchPath(pattern, pathname)
        );
        if (foundPattern) {
          if (
            id === 'user-profile' &&
            ['isOnLivestreamDetail', 'isOnLivestreamChat'].some(dataKey =>
              getIsMatchedPathname({ pathname, dataKey })
            )
          ) {
            return false;
          }

          if (foundPattern === '/post/:postId') {
            if (layoutType === 'story' && id === 'short-detail') return true;
            if (layoutType === 'flix' && id === 'flix-detail') return true;
            if (layoutType === 'post' && id === 'post-detail') return true;

            return false;
          }

          return true;
        }
      }) || {};

    return {
      ...defaultData,
      ...(isEmpty(matchedData) ? { shouldHidden: true } : matchedData),
    };
  }, [
    articleConfigKey,
    articleId,
    browseTabs,
    chatroomIdFromParam,
    dispatch,
    displayName,
    isDisplayNameEnabled,
    isMeProfile,
    isPublished,
    layoutType,
    locationState?.shouldShowChatroomMediaLibrary,
    meId,
    messageId,
    pathname,
    senderId,
    shouldShowCreatorIcon,
    userId,
    username,
    iframeViewerTitle,
  ]);
}

export default useNavHeader;
